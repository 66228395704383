import consumer_trains_common_queryOverweightBecause from '@/lib/data-service/haolv-default/consumer_trains_common_queryOverweightBecause'
import consumer_t_od_hotel_order_interim_checkHotelPolicy from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_checkHotelPolicy";// 酒店差标检测
import consumer_flight_search_flightVerification from '@/lib/data-service/haolv-default/consumer_flight_search_flightVerification'
import consumer_trains_product_checkTrainsPolicy from '@/lib/data-service/haolv-default/consumer_trains_product_checkTrainsPolicy'
export default {
    name: 'CheckTravel',
    data () {
        return {
            dialogVisible: false,
            businessType: '',
            initParams: {
                businessType: '',
                businessParams: {}
            },
            flightResultList: [],
            hotelResultList: [],
            trainsResultList:[],
            flightExceedingList: [],
            controlRules: '',
            controlParams: {},
            warningList: [],
            promiseResolve: '',
            promiseReject: '',
            reasonParams: {
                reason: '', // 原因
            },
            reasonRule: {
                reason: [
                    { required: true, message: '请选择超标原因', trigger: 'change' }
                ]
            },


            // warningTableForm: [],
            //
            // reasonList: [{value: 1, label: '余票不足'}, {value: 2, label: '出发或到达时间不合适'}, {value: 3, label: '出发到达车站选择'}],
            //
            //
            // canReserve: true,
        }
    },
    components: {},
    created () {},
    mounted () {
        this._getReasonList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        check(params) {
            const __this = this
            let p = new Promise(((resolve, reject) => {
                __this.promiseResolve = resolve
                __this.promiseReject = reject
                if (!params) params = {}
                // 判断各入参是否符合标准
                if (!params.businessType) {
                    params.businessType = ''
                }
                if (!params.businessParams) {
                    params.businessParams = {}
                }
                this.businessType = params.businessType
                this.initParams = params
                if (this.businessType === 1) {
                    // 机票
                    consumer_flight_search_flightVerification(this.initParams.businessParams).then(res => {
                        // 返回null就是全员通过
                        if (!res.datas) {
                            resolve({})
                            return
                        }
                        __this.controlParams = res.datas
                        __this.controlRules = res.datas.controlRules
                        __this.warningList = res.datas.reserveVerificationList
                        // 机票： 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                        __this._checkResult()
                    })
                } else if (this.businessType === 2) {
                    // 火车票
                    consumer_trains_product_checkTrainsPolicy(this.initParams.businessParams).then(res => {
                        // 返回null就是全员通过
                        if (!res.datas) {
                            resolve({})
                            return
                        }
                        __this.controlParams = res.datas
                        __this.controlRules = res.datas.controlRules
                        __this.warningList = res.datas.policies
                        // 火车票： 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                        __this._checkResult()
                    })
                } else {
                    // 酒店
                    consumer_t_od_hotel_order_interim_checkHotelPolicy(this.initParams.businessParams).then(res => {
                        // 返回null就是全员通过
                        if (!res.datas) {
                            resolve({})
                            return
                        }
                        __this.controlParams = res.datas
                        __this.controlRules = res.datas.controlRules
                        __this.warningList = res.datas.policyInfos
                        // 酒店： 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                        __this._checkResult()
                    })
                }
            }))
            return p
        },
        _checkResult() {
            const __this = this
            if (this.controlRules === 3) {
                // 不允许预定
                __this.dialogVisible = true
            } else {
                // 需要填原因
                __this.reasonParams.reason = ''
                __this.warningList.forEach(value => {
                    value.reason = ''
                })
                __this.dialogVisible = true
            }
        },
        _checkErrorTableForm() {
            const __this = this
            __this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (__this.businessType === 1) {
                        __this.warningList.forEach(value => {
                            value.reason = __this.reasonParams.reason
                            value.businessType = 1
                            value.controlRules = __this.controlParams.controlRules
                            value.userRealName = value.username
                            value.violationOfReason = __this.controlParams.controlRules
                            value.seatName = value.standardContent
                        })
                    } else if (__this.businessType === 2) {
                        __this.warningList.forEach(value => {
                            value.reason = __this.reasonParams.reason
                            value.businessType = 2
                            value.controlRule = __this.controlParams.controlRules
                            value.overproofType = 1
                            // value.reserveContent = __this.controlParams.controlRules
                            value.reserveContent = __this.reasonParams.reason
                        })
                    } else {
                        __this.warningList.forEach(value => {
                            value.reason = __this.reasonParams.reason
                            value.businessType = 3
                            value.controlRule = __this.controlParams.controlRules
                            value.overproofType = 1
                            // value.reserveContent = __this.controlParams.controlRules
                            value.reserveContent = __this.reasonParams.reason
                        })
                    }

                    __this.controlParams.policies = __this.warningList
                    __this.dialogVisible = false
                    __this.promiseResolve(__this.controlParams)
                    // __this.$refs['refuse'].removeEventListener()
                }
            })
        },
        _checkErrorTableRefuse() {
            const __this = this
            __this.dialogVisible = false
            __this.promiseReject(__this.controlParams)
        },
        _checkErrorTableCancel() {
            const __this = this
            __this.dialogVisible = false
            __this.promiseReject()
        },

        _getReasonList() {
            consumer_trains_common_queryOverweightBecause().then(res => {
                this.flightResultList = res.datas.flightResult
                this.hotelResultList = res.datas.hotelResult
                this.trainsResultList = res.datas.trainsResult
                this.flightExceedingList = res.datas.flightExceedingStandard
            })
        },
        delRow({row, rowIndex}) {
            if (row.controlRules === 4) {
                return 'display-none'
            } else {
                return ''
            }
        },

        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            const __this = this
            if (column.label === '选择原因' || column.label === '管控规则') {
                if (rowIndex === 0) {
                    return {
                        rowspan: __this.warningList.length,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/