// 酒店差标检测 /yinzhilv-tmc/酒店订单/checkHotelPolicyUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_hotel_order_interim_checkHotelPolicy = (pParams) => {

    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/checkHotelPolicy',
        data: pParams,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_checkHotelPolicy;
